import * as React from 'react'

import styles from './footer.module.css'

export default function Footer() {
  return (
    <footer className={styles.footer}>
        Made with love by the &nbsp;
        <span className={styles.siteName}>Frontend Weekly Team</span>
    </footer>
  )
}
